import React from 'react'
import {getRank} from '../rank'
import {getRecordsToday, getStreaks, streakRepairPossible} from '../db/StretchRecord'

const IS_NOT_WRIST = /^(?!wrist)[a-z]+/;
const IS_WRIST = /wrist/;


class streakcounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: "",
      anyStreak: "0",
      otherStreak: "0",
      rank: "",
      repairPossible: false,
    }
  }

  async componentDidMount() {
    const isWrist = this.props.type === "wrist";
    const type = isWrist ? IS_WRIST : IS_NOT_WRIST;
    const streakThreshold = isWrist ? 6 : 2;
    const count = await getRecordsToday(type);
    const {anyStreak, otherStreak} = await getStreaks(streakThreshold, type);
    const repairPossible = await streakRepairPossible(type);

    const seed = isWrist ? `${otherStreak}W` : anyStreak;

    const rank = getRank(seed);

    this.setState({today: count, anyStreak, otherStreak, rank, repairPossible});
  }

  async componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      const isWrist = this.props.type === "wrist";
      const type = isWrist ? IS_WRIST : IS_NOT_WRIST;
      const count = await getRecordsToday(type);
      this.setState({today: count});
    }
  }

  render() {
    const isWrist = this.props.type === "wrist";
    const today = (<div className={`row justify-content-center ${isWrist ? "no-gutters" : "my-2"}`}>
        <p className="col text-center my-0">today: {this.state.today}</p>
        </div>)
    return (
      <React.Fragment>
        {!isWrist && today}
        <div className={`row justify-content-center ${isWrist ? "mt-2" : "no-gutters"}`}>
          <p className="col text-center border-bottom mb-0">
            {isWrist ? "wrist stretches" : "streak of daily stretching" }
          </p>
        </div>
        {isWrist ? today: (<div className="row justify-content-center no-gutters my-0">
          <p className="col text-center my-0">any: {this.state.anyStreak}</p>
        </div>)}
        <div className="row justify-content-center no-gutters">
          <p className="col text-center my-0">{isWrist ? 6 : 2}+: {this.state.otherStreak}</p>
        </div>
        <div className="row justify-content-center no-gutters">
          <p className="col text-center my-2">Rank: {this.state.rank}</p>
        </div>
        {this.state.repairPossible && (<div className="row justify-content-center no-gutters">
          <p className="col text-center my-2">streak repair possible</p>
        </div>)}
        </React.Fragment>);
  }
}

export default streakcounter;
