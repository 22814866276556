import seedrandom from 'seedrandom';

const ADJECTIVES = ['affectionate','agreeable','amiable','bright','charming','creative','determined','diligent','diplomatic','dynamic','energetic','friendly','funny','generous','giving','gregarious','hardworking','helpful','imaginative','kind','likable','loyal','patient','polite','sincere','adept','brave','capable','considerate','courageous','faithful','fearless','frank','humorous','knowledgeable','loving','marvelous','nice','optimistic','passionate','persistent','plucky','proficient','romantic','self-confident','sensible','thoughtful','warmhearted','willing','zestful','amazing','awesome','blithesome','excellent','fabulous','favorable','fortuitous','gorgeous','incredible','unique','mirthful','outstanding','perfect','philosophical','propitious','remarkable','rousing','spectacular','splendid','stellar','stupendous','super','upbeat','stunning','wondrous','alluring','ample','bountiful','brilliant','breathtaking','dazzling','elegant','enchanting','gleaming','glimmering','glistening','glittering','glowing','lovely','lustrous','magnificent','ravishing','shimmering','shining','sleek','sparkling','twinkling','vivid','vibrant','vivacious','adaptable','ambitious','approachable','competitive','confident','customer-focused','devoted','educated','efficient','fast-paced','flexible','focused','honest','independent','inquisitive','insightful','open-minded','organized','personable','productive','qualified','relaxed','resourceful','responsible','technological','engaging','engrossing','captivating','fantastic','gripping','heart-warming','hilarious','imaginative','moving','powerful','spellbinding','thought-provoking'];

const NOUNS = ['actor','gold','painting','advertisement','grass','parrot','afternoon','greece','pencil','airport','guitar','piano','ambulance','hair','pillow','animal','hamburger','pizza','answer','helicopter','planet','apple','helmet','plastic','army','holiday','portugal','australia','honey','potato','balloon','horse','queen','banana','hospital','quill','battery','house','rain','beach','hydrogen','rainbow','beard','ice','raincoat','bed','insect','refrigerator','belgium','insurance','restaurant','boy','iron','river','branch','island','rocket','breakfast','jackal','room','brother','jelly','rose','camera','jewellery','russia','candle','jordan','sandwich','car','juice','school','caravan','kangaroo','scooter','carpet','king','shampoo','cartoon','kitchen','shoe','china','kite','soccer','church','knife','spoon','crayon','lamp','stone','crowd','lawyer','sugar','daughter','leather','sweden','death','library','teacher','denmark','lighter','telephone','diamond','lion','television','dinner','lizard','tent','disease','lock','thailand','doctor','london','tomato','dog','lunch','toothbrush','dream','machine','traffic','dress','magazine','train','easter','magician','truck','egg','manchester','uganda','eggplant','market','umbrella','egypt','match','van','elephant','microphone','vase','energy','monkey','vegetable','engine','morning','vulture','england','motorcycle','wall','evening','nail','whale','eye','napkin','window','family','needle','wire','finland','nest','xylophone','fish','nigeria','yacht','flag','night','yak','flower','notebook','zebra','football','ocean','zoo','forest','oil','garden','fountain','orange','gas','france','oxygen','girl','furniture','oyster','glass','garage','ghost'];

function getRank(seed) {
  const rng = seedrandom(seed);
  let result = rng();
  const adjective= ADJECTIVES[Math.floor(ADJECTIVES.length*result)];
  const noun= NOUNS[Math.floor(NOUNS.length*result)];
  return `${adjective} ${noun}`;
}
window.getRank = getRank;

export {getRank}
