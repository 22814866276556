import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { coreEx, legHold, quadStretch, calf, resume } from './stretches'
import { clearLocalStorage } from './resumablestretch'
import { execute, stopQueue } from './workers/queue'
import Streakcounter from './components/streakcounter'
import { FILES } from './audio/files'
import {dbOnLoad} from './db/StretchRecord'

const synth = window.speechSynthesis;
var audioContext;

dbOnLoad();

async function say(text) {
  if (FILES[text]) {
    await play(text)
  } else {
    await ttsSay(text);
  }
}

async function play(text) {
  const audioObj = new Audio(FILES[text])
  const track = audioContext.createMediaElementSource(audioObj);
  track.connect(audioContext.destination);
  await new Promise(function(resolve) {
    audioObj.onended = (e) => {
      resolve();
    }
    audioObj.play();
  });
}

async function ttsSay(text) {
  synth.cancel()
  const ssu = new SpeechSynthesisUtterance(text);
  await new Promise(function(resolve) {
    ssu.onend = (e) => {
      synth.cancel()
      resolve();
    }
    synth.speak(ssu);
  });
}

async function consumer() {
    const executeIterator = execute();
  for await (const text of executeIterator) {
    await say(text)
  }
}

function clickEx(exFn, stretchState) {
  return () => {
    if (!audioContext) {
      audioContext = new AudioContext();
    }

    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }

    if (audioContext.state === 'closed') {
      audioContext = new AudioContext();
    }

    exFn(stretchState);
    consumer();
    navigator.mediaSession.setActionHandler('stop', function() {
      stop();
    });
  }
}

function clickResume(lsActive, lsRep, stretchState) {
  if (!audioContext) {
    audioContext = new AudioContext();
  }

  if (audioContext.state === 'suspended') {
    audioContext.resume();
  }

  if (audioContext.state === 'closed') {
    audioContext = new AudioContext();
  }

  resume(lsActive, lsRep, stretchState)
  consumer();
  navigator.mediaSession.setActionHandler('stop', function() {
    stop();
  });
}

async function stop(setActive, setRep) {
  synth.pause();
  if (audioContext && audioContext.state !== 'closed') {
    audioContext.suspend();
    await audioContext.close();
  }
  stopQueue();
  synth.cancel();
  clearLocalStorage();
  setActive("");
  setRep("");
}

function App() {
  const [active, setActive] = useState("");
  const [rep, setRep] = useState("");
  const [prompts, setPrompts] = useState(false);
  const stretchState = {setActive, setRep, prompts};
  const lsActive = localStorage.getItem('active')
  const lsRep = localStorage.getItem('rep')
  const resumePossible = active === "" && lsActive !== null && lsActive !== "";
  useEffect(() => {
    if (lsActive !== active && active !== "") {
      console.log(`active: ${active}`)
      localStorage.setItem('active', active)
    }
    if (lsRep !== rep && rep !== "") {
      console.log(`rep: ${rep}`)
      localStorage.setItem('rep', rep)
    }
    if (active === "" && rep === "") {
      console.log("clearing")
      localStorage.setItem('active', active)
      localStorage.setItem('rep', rep)
    }
  })
  return (
    <div className="container">
      <div className="row pt-5 justify-content-between">
        <button className="btn btn-primary col-3 mx-auto" onClick={clickEx(coreEx, stretchState)}>core</button>
        <button className="btn btn-primary col-3 mx-auto" onClick={clickEx(legHold, stretchState)}>leg hold</button>
      </div>
      <div className="row pt-5 justify-content-between">
        <button className="btn btn-primary col-3 mx-auto" onClick={clickEx(quadStretch, stretchState)}>quad</button>
        <button className="btn btn-primary col-3 mx-auto" onClick={clickEx(calf, stretchState)}>calf</button>
      </div>
      <div className="row pt-5 justify-content-center">
        <button className="btn btn-danger col-2 mx-auto" onClick={() =>{ stop(setActive, setRep)}}>stop</button>
      </div>
      <div className="row justify-content-center mt-3">
        <p className="col text-center">current: {active}</p>
      </div>
      <div className="row justify-content-center mb-0">
        <p className="col text-center mb-0">rep: {rep}</p>
      </div>
      <Streakcounter active={active} type={"main"}/>
      <div className="row pt-5 justify-content-between">
        <button className={`btn btn-${prompts ? 'dark' : 'light'} col-3 mx-auto`} onClick={() => {setPrompts(!prompts)}}>prompts</button>
      </div>
      <div className="row pt-5 justify-content-between">
        <button className={`btn btn-${resumePossible ? 'dark' : 'light'} col-3 mx-auto`} onClick={() => {clickResume(lsActive, lsRep, stretchState)}}>resume</button>
      </div>
      <div className="fixed-bottom">
        <p className="text-monospace" style={{"fontSize":"0.5rem"}}>v3.1.3</p>
      </div>
    </div>
  );
}

export default App;
