import { addStretch } from '../db/StretchRecord'
import { clearLocalStorage } from '../resumablestretch'

const commandQueue = [];

function queueSay(text) {
    commandQueue.push({ command: 'say', args: [text]})
}

function queueSetActive(fn, name) {
    commandQueue.push({ command: 'setActive', args: [fn, name]})
}

function queueSetRep(fn, name) {
    commandQueue.push({ command: 'setRep', args: [fn, name]})
}

function queueSleep(dur) {
    commandQueue.push({ command: 'sleep', args: [dur]})
}

function queueMetadata(command, stretch) {
    commandQueue.push({ command: 'metadata', args: [command, stretch]})
}

function queueRecord(stretch) {
  commandQueue.push({ command: 'record', args: [stretch]})
}

function queueClearResumable() {
  commandQueue.push({command: 'clearResumable'})
}

async function* execute() {
    while (commandQueue.length > 0) {
          let cmd = commandQueue.shift();
          switch (cmd.command) {
                  case 'record':
                      addStretch(cmd.args[0])
                      break;
                  case 'metadata':
                      setSession(cmd.args[0], cmd.args[1])
                      break;
                  case 'say':
                      yield cmd.args[0]
                      break;
                  case 'setActive':
                    await cmd.args[0](cmd.args[1])
                    break;
                  case 'setRep':
                    await cmd.args[0](cmd.args[1])
                    break;
                  case 'sleep':
                    await sleep(cmd.args[0])
                    break;
                  case 'clearResumable':
                    await clearLocalStorage()
                    break;
                  default:
                      console.log('unrecognized command');
                  }
        }
    return commandQueue;
}

function setSession(step, stretch) {
  let metadataObj = {}
  if (step) {
        metadataObj.title=step;
  }
  if (stretch) {
        metadataObj.album=stretch;
  }
  navigator.mediaSession.metadata = new window.MediaMetadata();

}




async function sleep(dur) {
    return new Promise((resolve) => {
          setTimeout(() => { resolve() }, dur);
        })
}

function stopQueue(){
  commandQueue.splice(0,commandQueue.length);
}

export { queueSay, queueSleep, execute, stopQueue, queueMetadata, queueRecord, queueSetActive, queueSetRep, queueClearResumable}
