const DB_VERSION = 5;

async function dbOnLoad() {
  const DBOpenRequest = window.indexedDB.open("stretchrecord", DB_VERSION);
  let db;

  const p = new Promise(function (resolve, reject) {

    DBOpenRequest.onerror = function(event) {
      console.error('Error loading database.');
      reject(event);
    };

    DBOpenRequest.onsuccess = function(event) {
      console.log('Database initialised.');

      // store the result of opening the database in the db variable. This is used a lot below
      db = DBOpenRequest.result;
      resolve(db);
    }

    DBOpenRequest.onupgradeneeded = async function(event) {
      let db = event.target.result;

      let objectStore;
      if (!db.objectStoreNames.contains("stretchrecord")) {
         objectStore = db.createObjectStore("stretchrecord", {autoIncrement: true});
      } else {
         objectStore = DBOpenRequest.transaction.objectStore("stretchrecord");
      }

      if(!objectStore.indexNames.contains('timestamp')) {
        objectStore.createIndex("timestamp", "timestamp", { unique: true });
      }
      if(!objectStore.indexNames.contains('stretchName')) {
        objectStore.createIndex("stretchName", "stretchName", { unique: false });
      }
      if(!objectStore.indexNames.contains('stretchesByTime')) {
        objectStore.createIndex("stretchesByTime", ["timestamp","stretchName"], { unique: true });
      }
    }
  });

  await p;
  return p;
}

async function addStretch(stretchName, date=new Date()){
  const db = await dbOnLoad();
  let newItem = {stretchName, timestamp: date.getTime()};

  // open a read/write db transaction, ready for adding the data
  let transaction = db.transaction(["stretchrecord"], "readwrite");

  // report on the success of the transaction completing, when everything is done
  transaction.oncomplete = function() {
    console.log('Transaction completed: database modification finished.');
  }

  transaction.onerror = function() {
    console.error(`Error with transaction. ${transaction.error}`);
  }

  let objectStore = transaction.objectStore("stretchrecord");

  let objectStoreRequest = objectStore.add(newItem);

  const p = new Promise((resolve) => {
  objectStoreRequest.onsuccess = function(event) {
    console.log('record added to object store');
    resolve();
  }
  });
  await p;
  return p;
}


async function recordsInRange(lower, upper, type=/./){
  const keyRangeValue = IDBKeyRange.bound(lower,upper);
  const db = await dbOnLoad();
  const transaction = db.transaction(['stretchrecord'], 'readonly');
  const objectStore = transaction.objectStore("stretchrecord");
  const timestampIndex = objectStore.index('timestamp');

  let p = new Promise((resolve) => {
    let count = 0; 
    timestampIndex.openCursor(keyRangeValue).onsuccess = (event) => {
      var cursor = event.target.result;
      if(cursor) {
        if (cursor.value.stretchName.match(type)) {
          count++;
        }
        cursor.continue();
      } else {
        resolve(count);
      }
    }
  });
  let stretchCount = await p;
  return stretchCount;
}

async function getRecordsToday(type) {
  return await getDayRecords(0, type);
}

async function getDayRecords(offset_from_today=0, type=/./) {
  const d = new Date();
  var daylower = Date.UTC(d.getUTCFullYear(),d.getUTCMonth(), d.getUTCDate()+offset_from_today , 
          0, 0, 0, 0);
  var dayupper = Date.UTC(d.getUTCFullYear(),d.getUTCMonth(), d.getUTCDate()+offset_from_today+1 , 
          0, 0, 0, 0);

  return await recordsInRange(daylower, dayupper, type);
}

async function streakRepairPossible(type=/./, streakRepairThreshold=4) {
  const stretchesOnDay = await getDayRecords(-1, type);
  const stretchesOnDayBefore = await getDayRecords(-2, type);
  return stretchesOnDay === 0 && stretchesOnDayBefore > 0;
}

async function getStreaks(otherStreakThreshold=2, type=/./, streakRepairThreshold=4) {
  let anyStreak = 0, otherStreak = 0;
  let otherStreakPoss = true;
  let stretchesOnDay = await getDayRecords((anyStreak +1)*-1, type);
  let repair = false;
  while (stretchesOnDay > 0) {
    anyStreak++;
    if (stretchesOnDay >= otherStreakThreshold && otherStreakPoss) {
      otherStreak++;
    } else {
      otherStreakPoss = false;
    }
    if (stretchesOnDay >= streakRepairThreshold) {
      repair = true; 
    }
    stretchesOnDay = await getDayRecords((anyStreak +1)*-1, type);
    if (repair && stretchesOnDay <= 0) {
      const peek = await getDayRecords((anyStreak +2)*-1, type);
      if (peek > 0) {
        stretchesOnDay = peek; 
        otherStreakPoss = false;
        anyStreak++;
      }
    }
    repair = false;
  }
  return {anyStreak, otherStreak};
}

export {dbOnLoad, addStretch, getRecordsToday, recordsInRange, getStreaks, streakRepairPossible};
