import prepare_for_core_ex from '../resources/prepare_for_core_ex.mp3';
import push  from '../resources/push.mp3';
import cross  from '../resources/cross.mp3';
import start  from '../resources/start.mp3';
import pull  from '../resources/pull.mp3';
import prepare_for_leg_hold  from '../resources/prepare_for_leg_hold.mp3';
import left  from '../resources/left.mp3';
import right  from '../resources/right.mp3';
import done  from '../resources/done.mp3';
import good_job  from '../resources/good_job.mp3';
import rep_0  from '../resources/rep_0.mp3';
import rep_1  from '../resources/rep_1.mp3';
import rep_2  from '../resources/rep_2.mp3';
import rep_3  from '../resources/rep_3.mp3';
import rep_4  from '../resources/rep_4.mp3';
import rep_5  from '../resources/rep_5.mp3';
import rep_6  from '../resources/rep_6.mp3';
import rep_7  from '../resources/rep_7.mp3';
import rep_8  from '../resources/rep_8.mp3';
import rep_9  from '../resources/rep_9.mp3';
import rep_10  from '../resources/rep_10.mp3';
import ok from '../resources/ok.mp3'
import calf_instructions from '../resources/calf_instructions.mp3'
import prepare_for_quad from '../resources/prepare_for_quad.mp3'
import prepare_for_calf from '../resources/prepare_for_calf.mp3'
import quad_instructions from '../resources/quad_instructions.mp3'

const FILES = {
  "prepare for core exercise": prepare_for_core_ex,
  'push': push,
  'cross': cross,
  'start': start,
  'pull': pull,
  'prepare for leg hold': prepare_for_leg_hold,
  'left': left,
  'right': right,
  'done': done,
  'good job': good_job,
  'rep 0': rep_0,
  'rep 1': rep_1,
  'rep 2': rep_2,
  'rep 3': rep_3,
  'rep 4': rep_4,
'repetition 5': rep_5,
'repetition 6': rep_6,
'repetition 7': rep_7,
'repetition 8': rep_8,
'repetition 9': rep_9,
'repetition 10': rep_10,
'rep 5': rep_5,
'rep 6': rep_6,
'rep 7': rep_7,
'rep 8': rep_8,
'rep 9': rep_9,
'rep 10': rep_10,
  'ok': ok,
  'against the wall':calf_instructions,
  'prepare for quad stretch':prepare_for_quad,
  'prepare for calf stretch':prepare_for_calf,
  'foot on a surface with leg bent. go down and hold':quad_instructions,
}

export { FILES };
