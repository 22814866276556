import { queueSay, queueSetActive, queueSleep, queueMetadata, queueRecord, queueSetRep, queueClearResumable } from './workers/queue'

const SECOND = 1000;

async function resume(active, rep, stretchState) {
  const parsedRep = parseInt(rep)
  const resumeStretchState = { rep: parsedRep, ...stretchState}
  switch (active) {
    case 'core':
      coreEx(resumeStretchState)
      break;
    case 'leg hold':
      legHold(resumeStretchState)
      break;
    case 'calf':
      calf(resumeStretchState)
      break;
    case 'quad':
      quadStretch(resumeStretchState)
      break;
    default:
      console.log('unrecognized resume')
  }
}

async function coreEx({setActive, setRep, rep = -1}) {
  const name = "core";
  const hold = 30*SECOND;
  const next = 2*SECOND;
  if (rep < 0) {
    queueSetRep(setRep,0);
    queueMetadata("prepare", "core")
    queueSetActive(setActive, name);
    queueSay("prepare for core exercise");
    queueSleep(2000);

    queueMetadata("push")
    queueSay("push");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);
  }

  if (rep < 1) {
    queueSetRep(setRep,1);
    queueMetadata("cross")
    queueSay("cross");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

  }

  if (rep < 2) {
    queueSetRep(setRep,2);
    queueMetadata("pull")
    queueSay("pull");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);
  }

  if (rep < 3) {
    queueSetRep(setRep,3);
    queueMetadata("push")
    queueSay("push");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);
  }

  queueSetRep(setRep,"");
  queueSay("done");
  queueSleep(next);
  queueSay("good job");
  queueRecord(name);
  queueSetActive(setActive, "");
  queueClearResumable();
}

async function legHold({setActive, setRep, rep = 0}) {
  const name = "leg hold";
  const hold = 90*SECOND
  const next = 4*SECOND
  queueSetActive(setActive, name);
  queueSay("prepare for leg hold");
  queueSleep(next);

  for (var i = rep; i < 3; i++) {
    queueSetRep(setRep,i);
    queueSay(`rep ${i}`);
    queueSay("left");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

    queueSay("ok");
    queueSleep(next)

    queueSay("right");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

    queueSay("ok");
    queueSleep(next)
  }

  queueSay("done");
  queueSleep(next);
  queueSay("good job");
  queueRecord(name);
  queueSetActive(setActive, "");
  queueSetRep(setRep,"");
  queueClearResumable();
}

async function quadStretch({setActive, setRep, rep = 0}) {
  const name = "quad"
  const hold = 30*SECOND
  const next = 3*SECOND
  queueSetActive(setActive, name);
  queueSay("prepare for quad stretch");
  queueSleep(next);

  for (var i = rep; i < 3; i++) {
    queueSetRep(setRep,`${i}, left`);
    queueSay(`rep ${i}`);
    queueSay("left");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

    queueSay("ok");
    queueSetRep(setRep,`${i}, right`);
    queueSleep(next)

    queueSay("right");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

    queueSay("ok");
    queueSleep(next)
  }

  queueSay("done");
  queueSleep(next);
  queueSay("good job");
  queueRecord(name);
  queueSetActive(setActive, "");
  queueSetRep(setRep,"");
  queueClearResumable();
}


async function calf({setActive, setRep, rep = 0}) {
  const name = "calf"
  const hold = 30*SECOND
  const next = 3*SECOND
  queueSetActive(setActive, name);
  queueSay("prepare for calf stretch");
  queueSleep(next);

  for (var i = rep; i < 3; i++) {
    queueSetRep(setRep,`${i}, left`);
    queueSay(`rep ${i}`);
    queueSay("left");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

    queueSay("ok");
    queueSetRep(setRep,`${i}, right`);
    queueSleep(next)

    queueSay("right");
    queueSleep(next);
    queueSay("start");
    queueSleep(hold);

    queueSay("ok");
    queueSetRep(setRep,`${i+1}, left`);
    queueSleep(next)
  }

  queueSetRep(setRep,"");
  queueSay("done");
  queueSleep(next);
  queueSay("good job");
  queueRecord(name);
  queueSetActive(setActive, "");
  queueClearResumable();
}

export { coreEx, legHold, quadStretch, calf, resume}
